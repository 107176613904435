.bg-primary {
  background-color: #163174!important;
}
.btn-primary.btn-primary {
  background-color: #163174;
  border-color: #163174;
  color: #fff;
}
.btn-primary:hover {
   background-color: #2049b0;
}
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.show > .btn-primary.dropdown-toggle {
    
  background-color: #2049b0;
}
.navbar-brand img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.dark-mode .card-group .card {
  margin-bottom: 0;
}
.dark-mode .card-body {
  border-bottom: none;
}
.dark-mode .form-control.form-control {
  border: 1px solid #e4e7ea;
}
.dark-mode .login-right .card-body {
  background-color: transparent!important;
}
.dark-mode .card.bg-primary {
  border: none;
}
.login-page-logo {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.dark-mode .login-container {
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);
}