th, b, strong {
  font-weight: 500!important;
}
.rdt_Table {
	height: auto!important;
}
.cursor-pointer {
	cursor: pointer;
}
#modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal .material-modal-content {
  background: #fff;
  padding: 15px;
  min-width: 520px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.legend {
  font-size: 20px;
}
.card-columns {
  column-count: 1;
}
.form-sections .card {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.form-sections .card-body {
  padding-left: 3rem;
  padding-right: 3rem;
}
.form-sections .card-title {
  margin-bottom: .75rem;
  margin-top: -37px;
  background: #fff;
  display: inline-block;
  width: auto;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -5px;
  font-size: 20px;
}
.MuiFormControlLabel-label {
  font-size: 0.875rem!important;
}
.rw-widget.w-200 {
  text-align: left;
  width: 200px;
}

.rw-widget-input, .rw-filter-input {
  box-shadow: none!important;
  vertical-align: unset!important;
}
.rw-widget-input_{
  padding-bottom: 6px;
  padding-left: 6px;
}
.rw-multiselect-tag {
  margin-bottom: 6px;
  margin-left: 6px!important;
}
.rw-list {
  margin-left: 6px!important;
  margin-bottom: 6px!important;
}
.rw-list-option  {
  margin-top: 6px;
  margin-right: 6px;
  min-height: 28px;
}
.rw-dropdown-list-input, .rw-select {
  padding-top: 6px!important;
  padding-right: 6px!important;
}
.status-dropdown {
  width: 150px;
}
/*** Combobox start ***/
.rw-i.rw-i-caret-down:before {
  margin-right: -6px;
}
/*** Combobox end ***/

#transition-modal-description {
  overflow-y: auto;
  min-height: 50vh;
  max-height: 80vh;
  min-width: 800px;
  padding-right: 15px;
}
.tab-content {
  border-left: 0!important;
  border-right: 0!important;
  border-bottom: 0!important;
}


.sidebar .nav-link.external {
  background: inherit!important;
}

body.dark-mode.dark-mode, .sidebar.sidebar .cms_link.cms_link.active {
  background-color: transparent;
}

body.dark-mode.dark-mode, .sidebar.sidebar .cms_link.cms_link.active:hover {
  background: #20a8d8;
}

.sidebar {
  overflow-y: scroll;
}

.sidebar .nav-link.external:hover {
  background: #20A8D8!important;
}
.sidebar .nav-link.external .nav-icon {
  color: #73818f!important;
}
.sidebar .nav-link.external:hover .nav-icon {
  color: #fff!important;
}

@media (min-width: 575.98px) {
  .card-columns {
    column-count: 2!important;
  }
}


.theme-switcher {
  cursor: pointer;
}

.aliases-column {
  margin-left: -0.5rem!important;
}
.aliases-column .field-error {
  position: absolute;
}

.panel-filled {
  min-height: 380px;
  height: 380px;
  margin-bottom: 2rem;
  padding: 3rem;
  padding-top: 2.5rem;
  background-color: #ffffff;
}

#Top10CategoryByPaymentTurnover, #Top10BrandsByPaymentTurnover {
  height: 420px
}

.line-chart-container {
  min-height: 248px;
}

.rdt_ProgressComponent {
  width: 100%;
  padding-bottom: 60px;
  text-align: center;
  font-size: 24px;
}

.rdt_ProgressComponent, .rdt_NoDataComponent {
  width: 100%;
  padding-bottom: 60px;
  text-align: center;
  font-size: 18px;
}

.rw-placeholder {
  color: #949BA2;
}

.c-vr {
  border: 1px solid #ccc;
}

.card-header h5 {
  font-weight: 400;
}

.text-value-xl {
  font-size: 1.5rem;
}

body.dark-mode {
  background-color: #32353E;
  color: #949ba2;
  /*transition: background-color 550ms ease-in;*/
}
.dark-mode .navbar-brand {
  background-color: #163174!important;
  width: 200px!important;
}
.dark-mode .navbar-brand img {
  filter: brightness(0) invert(1);
}
.dark-mode .sidebar, .dark-mode .app-footer {
  background: #2A2D36;
}
.dark-mode .breadcrumb {
  background: transparent;
  border-bottom: none;
}
.dark-mode .app-footer {
  border-top: none;
  color: #949ba2;
}
.dark-mode #modal .material-modal-content {
  background-color: #32353E;
  color: #949ba2;
}
.dark-mode .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #383B44;
  background-clip: border-box;
  border: 1px solid #33363F;
  border-radius: 0.25rem;
}
.dark-mode .card-header {
  background-color: #383B44;
  color: #FCFFFF;
  border: 1px solid #33363F;
}
.dark-mode .card-title {
  background: #fff!important;
  color: #000;
}
.dark-mode label {
  color: #040D21;
}
.dark-mode .card-body,
.dark-mode .rdt_Table,
.dark-mode .rdt_TableBody,
.dark-mode .rdt_TableHeader,
.dark-mode .rdt_TableCol,
.dark-mode .rdt_TableRow,
.dark-mode .rdt_TableCell,
.dark-mode .rdt_Pagination,
.dark-mode .rdt_ProgressComponent,
.dark-mode .rdt_NoDataComponent,
.dark-mode .table th,
.dark-mode .table td
{
  background-color: #383B44;
  color: #040D21;
}
.dark-mode .bg-light {
  color: #000000!important;
}
.dark-mode .table td {
  color: #949ba2;
  border-color: #484c5a;
}
.dark-mode .MuiSvgIcon-root {
  fill: #2f353a;
}
.dark-mode .table th {
  border-color: #484c5a;
}
.dark-mode .rdt_TableHeadRow .rdt_TableCol {
  font-size: 14px;
  color: #ffffff;
}
.dark-mode .rdt_TableCol_Sortable, .dark-mode .rdt_TableCol_Sortable:hover {
  color: #ffffff;
}
.dark-mode .card-body,
.dark-mode .rdt_Table,
.dark-mode .rdt_TableHeadRow,
.dark-mode .rdt_TableCol,
.dark-mode .rdt_TableRow,
.dark-mode .rdt_TableCell,
.dark-mode .rdt_Pagination
{
  border-bottom: 1px solid #484c5a;
  color: #949ba2;
}
.dark-mode .rdt_TableHeadRow .rdt_TableCol {
  font-weight: 400;
}
.dark-mode .rdt_TableHeadRow, .dark-mode .rdt_Table, .dark-mode .rdt_TableBody, .dark-mode .rdt_TableBody .rdt_TableRow:last-child {
  border-bottom: none;
}
.dark-mode .rdt_Pagination {
  border-bottom: none;
}
.dark-mode .rdt_Pagination button svg {
  fill: #ffffff;
}
.dark-mode .rw-widget-container, .dark-mode .rw-input, .dark-mode .rw-dropdown-list-autofill, .dark-mode .rw-filter-input, .dark-mode .form-control {
  background: #494B54;
  color: #e8e8e8;
  /*border: 1px solid #33363F;*/
  border: 1px solid #4F5360;
}
.dark-mode .rw-widget-container:focus, .dark-mode .rw-input:focus, .dark-mode .rw-dropdown-list-autofill:focus, .dark-mode .rw-filter-input:focus, .dark-mode .form-control:focus {
  background: transparent;
  color: #949BA2;
  /*border: 1px solid #33363F;*/
  border: 1px solid #4F5360;
}
.dark-mode .rw-btn {
  color: #ffffff;
}
.dark-mode .rw-state-focus .rw-btn, .dark-mode .rw-open .rw-btn {
  color: #000000;
  background: inherit;
}
.dark-mode .rw-state-focus .rw-input, .dark-mode .rw-open .rw-input {
  color: #000000;
  background: inherit;
}
.dark-mode .rw-select:hover {
  background-color: transparent;
}
.dark-mode .rw-select-bordered {
  border: transparent;
}
.dark-mode .rw-multiselect-tag {
  background: #2A2D36;
  border: 1px solid #666;
}
.dark-mode .btn-light {
  background-color: transparent;
  border-color: #33363F ;
  color: #949ba2;
}
.dark-mode .MuiIconButton-label {
  color: rgba(255,255,255,0.5);
}

.dark-mode .nav-tabs .nav-link {
  color: #2f353a;
  background: #fff;
  border-color: #c8ced3;
  border-bottom-color: #2f353a;
}
.dark-mode .nav-tabs .nav-link.active {
  border: 1px solid;
  border-color: #2f353a #2f353a transparent #2f353a;
}
.dark-mode #modal .nav-tabs .nav-link.active {
  color: #888;
  border-bottom-color: #32353e!important;
}
.text-white,
.dark-mode .text-white * {
  color: white!important;
  fill: white!important;
}
.dark-mode .translations .tab-content, .dark-mode #modal .tab-content {
  background: transparent;
  border-color: #444444!important;
  border-left: 1px solid #444444 !important;
  border-right: 1px solid #444444 !important;
  border-bottom: 1px solid #444444 !important;
}
.remove-them-mode .dark-mode .form-control:disabled, .dark-mode .form-control[readonly] {
  background-color: #494b54;
  opacity: 1;
}

.remove-them-mode .dark-mode .panel-filled {
  background-color: rgba(68, 70, 79, 0.5);
}
.remove-them-mode .dark-mode .panel-filled h5 {
  color: #ffffff;
  font-weight: normal;
}

.rdt_TableHead *,
.dark-mode .panel-filled h5 {
  color: #000000;
  font-weight: normal;
}
.dark-mode .rdt_TableCol_Sortable, .dark-mode .rdt_TableCol_Sortable:hover {
  color: #000000;
}
.dark-mode.dark-mode .table td,
.dark-mode.dark-mode .table th {
  color: #040D21;
}

.remove-them-mode .dark-mode .sidebar .nav-link:hover, .dark-mode .sidebar .nav-link.external:hover  {
  color: #fff;
  background: #163174!important;
}
.dark-mode .card-header.card-header,
.dark-mode .card.card {
  background: #fff;
}
.dark-mode .card-header.card-header{
  color: #040D21;
}
.dark-mode .card-body, .dark-mode .rdt_Table, .dark-mode .rdt_TableHeadRow, .dark-mode .rdt_TableCol, .dark-mode .rdt_TableRow, .dark-mode .rdt_TableCell, .dark-mode .rdt_Pagination {
  border-bottom: 1px solid #f1f1f1;
  color: #040D21 !important;
}

.dark-mode .card-header.card-header {
  border: 1px solid transparent;
  border-bottom: 1px solid #f1f1f1;
}


.mapboxgl-ctrl-geocoder--input::placeholder,
.dark-mode .rw-widget-container input::placeholder,
.dark-mode .form-control::placeholder {
  opacity: 0.6 !important;
}

.rdt_TableCell .btn.btn-light,
.dark-mode .rw-widget-container,
.mapboxgl-ctrl-geocoder--input::placeholder,
.dark-mode .rw-widget-container input::placeholder,
.dark-mode .rw-widget-container input:placeholder-shown,
.dark-mode .rw-placeholder,
.dark-mode .form-control::placeholder,
.dark-mode .form-control:placeholder-shown,
.dark-mode .rw-input,
.dark-mode .rw-dropdown-list-autofill,
.dark-mode .rw-filter-input,
.dark-mode .form-control,
.dark-mode .search-btn.btn-outline-secondary,
.dark-mode .search-btn.btn-outline-secondary:focus,
.dark-mode .rw-widget-container:focus,
.dark-mode .rw-input:focus,
.dark-mode .rw-dropdown-list-autofill:focus,
.dark-mode .rw-filter-input:focus,
.dark-mode .form-control:focus {
  background-color:#f0f3f5 !important;
  color: #040D21 !important;
  border: none;
}

.dark-mode .rw-popup {
  background-color:#f0f3f5 !important;
  color: #040D21 !important;
}

.dark-mode.dark-mode .rw-btn {
  color: inherit;
}


.dark-mode .rw-input:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #949BA2 ;
}
.dark-mode .rw-input:focus::-moz-placeholder { /* Firefox 19+ */
  color: #949BA2;
}
.dark-mode .rw-input:focus:-ms-input-placeholder { /* IE 10+ */
  color: #949BA2;
}
.dark-mode .rw-input:focus:-moz-placeholder { /* Firefox 18- */
  color: #949BA2;
}
.dark-mode .rw-placeholder {
  color: #e8e8e8;
}
.dark-mode .rw-input::-webkit-input-placeholder, .dark-mode input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #e8e8e8;
}
.dark-mode .rw-input::-moz-placeholder, .dark-mode input::-moz-placeholder { /* Firefox 19+ */
  color: #e8e8e8;
}
.dark-mode .rw-input:-ms-input-placeholder, .dark-mode input:-ms-input-placeholder { /* IE 10+ */
  color: #e8e8e8;
}
.dark-mode .rw-input:-moz-placeholder, .dark-mode input:-moz-placeholder { /* Firefox 18- */
  color: #e8e8e8;
}
.dark-mode .c-vr {
  border: 1px solid #484c5a;
}
.dark-mode ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.reset-zoom {
  position: absolute;
  top: 60px;
  right: 60px;
}

.dark-mode .rw-open .rw-widget-container {
  color: #040D21 !important;
}

.dark-mode .rdt_TableHeadRow, .dark-mode .rdt_Table *,
.dark-mode .card-body, .dark-mode .card-body *, .dark-mode .rdt_Table, .dark-mode .rdt_TableBody, .dark-mode .rdt_TableHeader, .dark-mode .rdt_TableCol, .dark-mode .rdt_TableRow, .dark-mode .rdt_TableCell, .dark-mode .rdt_Pagination, .dark-mode .rdt_ProgressComponent, .dark-mode .rdt_NoDataComponent, .dark-mode .table th, .dark-mode .table td {
  background: transparent;
}

.dark-mode .rw-popup-container .rw-popup-transition .rw-popup {
  background: #fff;
}

.dark-mode .table thead th,
.dark-mode.dark-mode .table td {
  border-color: #f1f1f1;
}

.table.table {
  color: #040D21;
}

body:not(.dark-mode) .rw-popup.rw-popup,
body:not(.dark-mode) .rw-state-focus.rw-state-focus>.rw-widget-container,
body:not(.dark-mode) .rw-state-focus.rw-state-focus>.rw-widget-container:hover,
body:not(.dark-mode) .rw-widget-container.rw-widget-container,
body:not(.dark-mode) .rw-widget-container.rw-widget-container input,
body:not(.dark-mode) form input,
body:not(.dark-mode) form input:focus {
  background: #f1f1f1;
  color: #040D21;
}

.img-thumbnail.img-thumbnail {
  height: 10rem;
}

.img-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.panel-filled, .card.card {
  border-radius: 10px;
}

.card-header.card-header:first-child {
  border-radius: 10px 10px 0 0;
}

header .navbar-brand.navbar-brand,
header .dark-mode .navbar-brand.navbar-brand {
  background-color: #040D21 !important;
  width: 200px!important;
}

.sidebar.sidebar,
.dark-mode.dark-mode .sidebar,
.dark-mode.dark-mode .app-footer {
  background: #040D21;
}

body:not(.dark-mode) .rdt_TableHead *,
body:not(.dark-mode) .rdt_TableBody * {
  color: #040D21 !important;
}

body.dark-mode.dark-mode,
.sidebar.sidebar .nav-link.active {
  background-color: #1B2231;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.items-end.items-end {
  align-items: end;
}

.brand-map {
  min-height: 25rem;
}

.mapboxgl-ctrl-top-right {
  width: 100%;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px !important;
}

.mapboxgl-ctrl-geocoder--input {
  opacity: 0.9 !important;
  background-color: #f0f3f5 !important;
  color: #040D21 !important;
  border: 1px solid #e4e7ea !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 35px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(32 168 216 / 25%) !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group {
  opacity: 0.9 !important;
  background-color: #f0f3f5 !important;
  color: #040D21 !important;
  border: 1px solid #e4e7ea !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    width: calc(100% - 20px) !important;
    max-width: inherit !important;
  }
}

.coordinates {
  position: absolute;
  bottom: 64px;
  left: 28px;
  opacity: 0.9 !important;
  background-color: #f0f3f5 !important;
  color: #040D21 !important;
  border: 1px solid #e4e7ea !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.mapboxgl-ctrl-geocoder .suggestions {
  overflow: scroll !important;
  max-height: 20rem;
}

.rdt_TableCell:last-child > div {
  flex-shrink: 0;
}

.span-payouts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: black;
  font-weight: bold;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  background: transparent;
}