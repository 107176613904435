fieldset {
  border: 1px solid #ccc;
}

legend {
background: none;
width: auto;
padding-left: 15px;
padding-right: 15px; 
}

.form-error, .field-error {
  color: #ff0000;
}

.quill {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
 }

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #e4e7ea !important;
}

.ql-container.ql-snow {
  background-color: #f0f3f5 !important;
  color: #040D21 !important;
}

.quill b, .quill strong {
  font-weight: bolder !important;
}