// Here you can add other styles

#pagination-first-page,
#pagination-previous-page,
#pagination-next-page,
#pagination-last-page {
  margin-left: 4px;
}

#pagination-first-page svg,
#pagination-previous-page svg,
#pagination-next-page svg,
#pagination-last-page svg {
  fill: #f0f3f5;
}

#pagination-first-page:not(:disabled) svg,
#pagination-previous-page:not(:disabled) svg,
#pagination-next-page:not(:disabled) svg,
#pagination-last-page:not(:disabled) svg {
  fill: #1B2231;
}
