// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/all.min.css';
// Import Flag Icons Set
//@import '~flag-icon-css/css/flag.min.css';
// Import Font Awesome Icons Set
//@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

.log-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 50px;
  position: relative;
  margin-bottom: 20px;
}

.log-wrapper:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: calc(100% - 40px);
  transform: translateX(-50%) rotate(-0.7deg);
  height: 1px;
  background: #9E9E9E;
}

.log-wrapper h4,
.log-wrapper-no-result h4 {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}

.log .rdt_TableCell:last-child > div {
  flex-shrink: unset;
  white-space: normal;
}

.log-wrapper span {
  font-size: 14px;
  color: white;
}


.details span {
  display: block;
}

.log .rdt_TableRow{
  cursor: pointer;
}


.nav-icon {
  background-size: contain;
  background-repeat: no-repeat;
  height: 13px;
}

.nav-icon.dashboard {
  background-image: url('../assets/sidebar/dashboard.svg');
}

.nav-icon.brands {
  background-image: url('../assets/sidebar/brands.svg');
}

.nav-icon.offers  {
  background-image: url('../assets/sidebar/offers.svg');
}

.nav-icon.sepa {
  background-image: url('../assets/sidebar/SepaNew.svg');
}

.nav-icon.debit {
  background-image: url('../assets/sidebar/debit.svg');
}

.nav-icon.credit {
  background-image: url('../assets/sidebar/credit.svg');
}

.nav-icon.customers {
  background-image: url('../assets/sidebar/customers.svg');
}

.nav-icon.banks {
  background-image: url('../assets/sidebar/banks.svg');
}

.nav-icon.payments {
  background-image: url('../assets/sidebar/payments.svg');
}

.nav-icon.clicks {
  background-image: url('../assets/sidebar/clicks.svg');
}

.nav-icon.transactions {
  background-image: url('../assets/sidebar/transactions.svg');
}

.contract-types {
  background-image: url('../assets/sidebar/contract_types.svg');
}

.reviews {
  background-image: url('../assets/sidebar/reviews.svg');
}

.analytics {
  background-image: url('../assets/sidebar/analytics.svg');
}

.cms {
  background-image: url('../assets/sidebar/cms.svg');
}

.settings {
  background-image: url('../assets/sidebar/settings.svg');
}

.brands-account {
  background-image: url('../assets/sidebar/Add-brand.svg');
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
}