.rdt_Table {
	height: auto!important;
}
.cursor-pointer {
	cursor: pointer;
}
.search-btn {
}
.categories, .countries {
	height: 204px;
	overflow-y: scroll;
}
legend {
	margin-left: -15px;
}
div[name="contract_types"] .rw-state-disabled {
  font-weight: bold;
}
div[name="contract_types"] .rw-list-option:not(.rw-state-disabled) {
  padding-left: 20px!important;
}
