.translations .nav-tabs {
  border-bottom: 0px;
}
.translations .nav-tabs .nav-item {
  margin-bottom: 0px;
}
.translations .tab-content {
  border-left: 1px solid #c8ced3!important;
  border-right: 1px solid #c8ced3!important;
  border-bottom: 1px solid #c8ced3!important;
  border-radius: 0 3px 3px 3px;
}
