.customerrr .listValue > span {
    padding: 0;
    margin-bottom: 10px;
}

.customerrr input,
.customerrr select {
    margin-bottom: 10px;
}

.customerrr .listValue span  input {
    margin-bottom: 0;
}

.list-p p {
    margin-bottom:22.5px;
}

.disable-iban {
    opacity: 0.5;
}

.ibans-wrapper-button {
    position: relative;
}

.disable-iban:hover + span {
    opacity: 1;
}

.ibans-wrapper-button span {
    transition: 0.3s;
    white-space: nowrap;
    opacity: 0;
    left: 80px;
    position: absolute;
    top: 5.5px;
    border: 1px solid red;
    padding: 2px;
}