.rdt_Table {
	height: auto!important;
}
.cursor-pointer {
	cursor: pointer;
}
.search-btn {
}
.categories, .countries {
	height: 204px;
	overflow-y: scroll;
}
legend {
	margin-left: -15px;
}
